import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import People from '../images/people.jpg'
// import ThumbsUp from '../images/thumbup.jpg'
// import Fusion from "../images/f-logo.svg"
// import ContactUsMain from "../components/contactusmain"
import HeroMini from "../components/heroSmall"

const ContactUs = () => (
  <Layout>
    <Seo title="Contact Us" />
    <HeroMini title="Contact Us"/>
    <main className="section has-text-black">
      <div className="container pb-5">
      <div id='container2'>
      <h1>OMG, this is ON TOP OF THE MAP!</h1>
    </div>

    <div id='map-canvas'></div>


      </div>
    </main>
  </Layout>
)

export default ContactUs;